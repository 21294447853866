import React from 'react'
import styled from 'styled-components'
import { AppSideNavEmailName } from './AppSideNavEmailName'
import { AppEvolutionRestData } from './AppEvolutionRestData'
// import { AppNewsSection } from './AppNewsSection'

const AppEvolutionWithNavWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .DigitalPlatformSection {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    @media (max-width: 1090px) {
      flex-wrap: wrap;
      padding: 15px;
    }
  }
  .DigitalPlatformNav {
    order: 0;
    @media (max-width: 1090px) {
      order: 1;
      margin: auto;
    }
  }
  .DigitalPlatformSectionContent {
    order: 1;
    @media (max-width: 1090px) {
      order: 0;
    }
  }
`

export const AppEvolutionWithNav = () => {
  return (
    <AppEvolutionWithNavWapper>
      <div className='DigitalPlatformSection'>
        <div className='DigitalPlatformNav'>
          <AppSideNavEmailName />
        </div>
        <div className='DigitalPlatformSectionContent'>
          <AppEvolutionRestData />
        </div>
        {/* <div>
          <AppNewsSection />
        </div> */}
      </div>
    </AppEvolutionWithNavWapper>
  )
}
