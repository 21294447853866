import React from 'react'
import { AppResourcesEmailBanner } from './AppResourcesEmailBanner'
import EvolutionBannerData from '../Data/EvolutionBannerData.json'
import styled from 'styled-components'

const AppEvolutionBannerWapper = styled.div`
  .EvolutionSection {
    display: flex;
    justify-content: space-between;
    padding: 130px 50px 50px 50px;
    @media (max-width: 1090px) {
      flex-wrap: wrap;
      padding: 80px 15px 10px 15px;
    }
  }
  .EvolutionImageSection {
    @media (max-width: 1090px) {
      margin: auto;
      padding-top: 30px;
    }
  }
  .EvolutionHeading {
    max-width: 850px;
    font-size: 2.5rem;
    line-height: 1.30435em;
    padding: 0px 30px 0px 0px;
    @media (max-width: 1090px) {
      max-width: unset;
      padding: unset;
      font-size: 2.1rem;
    }
    @media (max-width: 420px) {
      font-size: 2.1rem;
    }
  }
  .EvolutionPara {
    max-width: 920px;
    padding: 15px 30px 15px 0px;
    @media (max-width: 1090px) {
      max-width: unset;
      padding: 15px 0px 15px 0px;
    }
  }
  .DateEvolutionPara {
    max-width: 850px;
    color: #34a1d5;
    font-size: 19px;
    padding: 15px 30px 0px 0px;
    @media (max-width: 1090px) {
      max-width: unset;
    }
  }
  .EvolutionBannerImage {
    width: 100%;
    height: auto;
    box-shadow: rgb(160 161 163 / 88%) 0px 3px 7px 0px;
    border-radius: 18px;
  }
  .DisplayNone {
    display: none;
  }
`

export const AppEvolutionBanner = () => {
  return (
    <AppEvolutionBannerWapper>
      {EvolutionBannerData.map((data, index) => {
        return (
          <div key={index}>
            <AppResourcesEmailBanner
              Heading={data.Title}
              Para={data.Leadpara}
              DatePara={data.DatePara}
              ImagePath={data.ImagePath}
              AltText={data.AltText}
              LeadParaStyle='DisplayNone'
              BannerHeading='EvolutionHeading'
              BannerPara='EvolutionPara'
              DateBannerPara='DateEvolutionPara'
              BannerImageSection='EvolutionImageSection'
              BannerImage='EvolutionBannerImage'
              BannerSection='EvolutionSection'
            />
          </div>
        )
      })}
    </AppEvolutionBannerWapper>
  )
}
