import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import EvolutionRestData from '../Data/EvolutionRestData'
import { ReUseHTwotag } from './ReUseHTwoTag'

const AppEvolutionRestDataWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .EvolutionSectionEmail {
    padding: 5px;
    @media (max-width: 1090px) {
      padding: 15px 5px 15px 5px;
    }
  }
  .HeadingEamilSpendAnalyticsOne {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    margin: auto;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .HeadingEamilSpendAnalytics {
    max-width: 1150px;
    padding: 15px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .LeadParaEvolutionStyleOne {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    margin: auto;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .LeadParaEvolutionStyle {
    max-width: 1150px;
    padding: 15px 15px 15px 45px;

    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .SubParaPonits {
    color: #047cc2;
    padding: 10px 0px 10px 0px;
    font-weight: bold;
    font-size: 21px;
  }
  .EvolutionSubHeadingPoint {
    color: #047cc2;
    max-width: 1150px;
    padding: 15px 15px 15px 45px;
    font-size: 22px;
    font-weight: bold;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .EvolutionSubPointsData {
    color: #047cc2;
    padding: 10px 10px 10px 0px;
    font-weight: bold;
    font-size: 21px;
  }
`

export const AppEvolutionRestData = () => {
  return (
    <AppEvolutionRestDataWapper>
      <div>
        {EvolutionRestData.map((data, index) => {
          return (
            <div className='MainEmailProcurementSection' key={index}>
              <div className='EvolutionSectionEmail'>
                <ReUsePtag para={data.BannerRestPara} paraStyle='LeadParaEvolutionStyleOne' />
                <ReUseHTwotag Heading={data.HeadingOne} HeadingStyle='HeadingEamilSpendAnalytics' />
                <ReUsePtag para={data.ParaOne} paraStyle='LeadParaEvolutionStyle' />
                <ReUsePtag para={data.ParaTwo} paraStyle='LeadParaEvolutionStyle' />
                <ReUsePtag para={data.ParaTwoPoint} paraStyle='LeadParaEvolutionStyle' />
                <ReUsePtag
                  Icon={data.ParaThree}
                  para={data.ParaThreePoint}
                  paraStyle='EvolutionSubHeadingPoint'
                  IconStyle='iconColor'
                />
                <ReUsePtag para={data.ParaFour} paraStyle='LeadParaEvolutionStyle' />
                <ReUsePtag para={data.ParaFive} paraStyle='LeadParaEvolutionStyle' />
                <ReUsePtag para={data.ParaFivePoint} paraStyle='LeadParaEvolutionStyle' />
                <ReUsePtag
                  Icon={data.ParaSix}
                  para={data.ParaSixPoint}
                  paraStyle='EvolutionSubHeadingPoint'
                  IconStyle='iconColor'
                />
                <ReUsePtag para={data.ParaSeven} paraStyle='LeadParaEvolutionStyle' />
                <ReUsePtag
                  Icon={data.ParaEight}
                  para={data.ParaEightPoint}
                  paraStyle='EvolutionSubHeadingPoint'
                  IconStyle='iconColor'
                />
                <ReUsePtag para={data.ParaNine} paraStyle='LeadParaEvolutionStyle' />
                <ReUseHTwotag Heading={data.HeadingTwo} HeadingStyle='HeadingEamilSpendAnalytics' />
                <ReUsePtag para={data.ParaTen} paraStyle='LeadParaEvolutionStyle' />
                <ReUsePtag para={data.ParaEleven} paraStyle='LeadParaEvolutionStyle' />
                <ReUsePtag para={data.ParaTwelve} paraStyle='LeadParaEvolutionStyle' />
                <ReUsePtag para={data.ParaTwelvePoint} paraStyle='LeadParaEvolutionStyle' />
              </div>
            </div>
          )
        })}
      </div>
    </AppEvolutionRestDataWapper>
  )
}
