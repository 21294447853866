import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    Icon: <FaArrowCircleRight />,
    BannerRestPara:
      'As enterprises realize the gain in risk mitigation, CMS has evolved into much more than just a system of records.  Here is where the Procurement function enters the equation.  As an organizational function focused on maximizing operational and financial performance, the modern CMS solution has expanded into promoting change, evaluating market volatility, driving performance, addressing risks via integrated processes across the enterprise, and much more.',
    HeadingOne: 'Foundation of an Effective CMS',
    ParaOne:
      'Driven by the realities put upon the world by COVID-19 in the creation of globally distributed workforces, the new norm now requires gaining access and creating a digitized environment to capture and identify the contractual risks over time.  This has created the requirement for an effective, automated, simple, analytics driven, secure CMS systems.',
    ParaTwo: 'The question now is, “which CMS is the right fit for your company?”.',
    ParaTwoPoint:
      'Companies must take a look at themselves, articulate and digest where their risk sits across the enterprise, and use these needs to evaluate and define their CMS key drivers.  This “slowing down to speed up” approach to risk mitigation will create the business and technical foundations necessary for your successful CMS digital transformation.',
    ParaThree: '⟶',
    ParaThreePoint: 'Simple and Flexible',
    ParaFour:
      'Ease of use is a desired element for any successful technology or business transformation.  Simply put, it drives platform adoption.  If the system is complex and requires extensive training or set up effort, then it can act as a barrier to technology adoption and compliance.',
    ParaFive:
      'While the system must be powerful, it should also be intuitive, flexible, simple to navigate, and easy to consume.  Most critically, your CMS future footprint should be designed to create a single-source of truth.  Essentially, numerous “point solutions” would collapse into the ‘go-forward’ CMS of choice.  This drives cost reduction and generates efficiencies, as well as reduced systems support issues from your own team and the various system suppliers.',
    ParaFivePoint:
      'Given the above, a rapid ingestion from the various incumbent point solution systems, into the singular CMS solution should be a quick and automated process.  Each architectural component of your environment should conceptually have seamless integration with other systems like ERP, CRM, digital signature, etc. to create a unified digital approach.  If users encounter obstacles as soon as they try a new technology, these systems will be challenged to be successful, preventing the organization from realizing any potential benefits.  A simple and intuitive system will ensure the first leg of contract digitization is not thrown off by integration and consumption issues.',
    ParaSix: '⟶',
    ParaSixPoint: 'Automated and Analytics Driven',
    ParaSeven:
      'Beyond usability, a digitized CMS should be an intelligent, fully automated, analytics driven solution for better decision making and risk mitigation.  As established above, a holistic system will help business leaders plan, prioritize, execute, monitor and improve performance.  The system should have flexible workflows to address contract approval and tracking scenarios that can apply business rules based on spend category, geography, role, spend approval limit, industry-specific requirements and more.',
    ParaEight: '⟶',
    ParaEightPoint: 'Scalable ',
    ParaNine:
      'With an easily usable CMS, capable of supporting advanced digital capabilities, the next thing is to focus on is solution scalability.  Oftentimes, technology solutions are rolled out in one geography to test and ensure success, prior to rolling out ‘globally.  Ensuring your solution can support enterprise-wide use is critical. In doing so, soon your company can consume and gain the benefits of any contract, at any time, in any region where your business operates.  The solution should be accessible and user-friendly enough to promote user adoption to address their specific need.',
    HeadingTwo: 'Selecting the Optimal CMS',
    ParaTen:
      'Raindrop was designed specifically to help companies secure a full view of their contractual supplier commitments, and in turn, apply this information to begin creating a proactive spend management strategy.  What was historically a reactive function, has now turned into a predictive and proactive approach to acquiring goods and services inside your company, designed to benefit the running of your company.',
    ParaEleven:
      'This is where the benefits of Raindrop’s Contract Management module comes in.  All too often, CMS software becomes a “data jail” – literally a virtual repository where documents of all types are kept, but not communicating with the business functions who should otherwise benefit from the valuable information inside.  Instead of an expensive and frequently underutilized point-solution system for merely storing contracts (generally used by Legal departments and/or Sales Operations), Raindrop extracts the critical contractual metadata and uses this proactively to ensure future awareness and secure maximum business value across the enterprise.  Raindrop’s Contract Management module is powered by artificial intelligence (AI) and machine learning (ML) to extract and translate the contract document value, to the being able to apply it in real time.  This provides visibility and unparalleled capabilities to take your contract digitization requirements to next level.',
    ParaTwelve:
      'Every company runs on contracts and the money these contracts commit.  Raindrop is specifically designed as a single platform to solve one of every company’s most critical challenges, namely, “how and where are we spending our money?”.  What you do with these contracts can move you up the value chain.  Raindrop’s easy-to-use interface enables users to address contracts as desired, further enabling you to create and share reports with easily accessible layers of analytics.  Business stakeholders have the visibility they need with actionable insights for Operations, Procurement, Finance, and Sales to optimize the financial outflow of funds to their suppliers.',
    ParaTwelvePoint:
      'Raindrop also provides automatic alerts for upcoming contractual expirations and renewals, so your team will never miss a notification deadline.  Raindrop assigns events to the current Procurement / business owner / and other internal stakeholders, even if the prior contract owners have left the organization.  Raindrop continues to identify risks and opportunities based on commodity pricing and has configurable workflows to streamline the approval process.  Bottom line?  Know where your money is going and what you’re getting for it.  That’s the power of Raindrop.',
    ParaTwelvePointOne:
      'Raindrop provides automatic alerts for upcoming contractual expirations and renewals, so your team will never miss a notification deadline.  Raindrop assigns events to the current Procurement, business owner, and other internal stakeholders, even if the prior contract owners have left the organization.  Raindrop continues to identify risks and opportunities based on commodity pricing, and has configurable workflows to streamline the approval process.  Bottom line?  Know where your money is going and what you’re getting for it.  That’s the power of Raindrop.',
    HeadingThree: 'Raindrop Value Proposition',
    ParaThirteen: 'Collaborative ⟶',
    ParaThirteenPonit:
      'Easily collaborates with internal teams and maintain internal chat conversations for any contract. Contract request from suppliers and be easily requested and tracked.',
    ParaFourteen: 'Streamline approval process ⟶',
    ParaFourteenPoint:
      'Easily configurable and flexible workflows to create your own approval metrices. Integration to other systems like E-signature help to maintain a full audit trail from request to signature.',
    ParaFifteen: 'Obligation tracking ⟶',
    ParaFifteenPonit:
      'Captures the financial terms of each document to provide visibility, allowing Raindrop to track obligations and actual spend throughout the entire term.',
    ParaSixteen: 'Automate for Efficiency ⟶',
    ParaSixteenPoint:
      'Raindrop solves the age-old problem of unanticipated contract renewals. Receive an automated call-to-action to ensure there are no surprises when contracts are nearing expiration or other milestone deadlines. Raindrop provides automatic alerts for upcoming expirations and renewals, so your team will never miss a notification deadline. Raindrop assigns events to the current sourcing and business owners, even if the prior contract owners have left the organization, It identifies risks and opportunities based on commodity pricing, and has configurable workflows to streamline the approval process.',
    ParaSeventeen: 'Powerful Analytics based reporting ⟶',
    ParaSeventeenPoint:
      ' Unleash the value of contract data to easily answer important business questions like, “How many contracts will expire next quarter?” and “How much could we save if we pay invoices early until the end of the fiscal year?” Beyond typical reporting, Raindrop provides valuable calls to action.',
    ParaEighteen: 'Accurate Forecasting ⟶',
    ParaEighteenPoint:
      'Contracts often entitle the supplier to increase their price during or after a contract term. Raindrop automatically includes all contractually- committed price increases in spend forecasts, so you avoid unpleasant surprises.',
  },
]
